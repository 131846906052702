<template>
  <v-container class=" d-inline-flex mx-5 " max-width="auto">
    <!-- <v-data-table
      :headers="headers"
      :items="schools"
      :items-per-page="13"
      class=""
      v-model="selected"
      :single-select="singleSelect"
      item-key="id"
    ></v-data-table> -->
    <iframe :width="iframe.width" :height="iframe.height" src="https://lookerstudio.google.com/embed/reporting/6eb990c8-6cce-4929-a7dd-f05c9bc59005/page/p_1vzywyx04c" frameborder="0" style="border:0" allowfullscreen></iframe>
  </v-container>
</template>

<script>
import { sync, dispatch } from "vuex-pathify";

export default {
  computed: {
    ...sync("school", ["schools",'selSchool']),
  },
  data() {
    return {
      iframe:{
        height:0.95*window.innerHeight,
        width:'100%',
      },
      headers: [
        {
          text: "Schools",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
      singleSelect:false,
      loading: false,
      selection: 1,
      selected: [],
      chartData: {
        labels: ["A", "B", "C"],
        series: [
          [1, 1, 2],
          [4, 6, 5],
        ],
      },
      chartOptions: {
        lineSmooth: false,
      },
    };
  },

  watch:{
    selected(){
      dispatch("school/getSchool",this.selected[0].id)
    }
  },

  beforeMount() {
    this.loadData();
  },

  methods: {
    async loadData() {
      await dispatch("school/loadData");
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>
